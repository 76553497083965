import { lang } from "moment"
import React, { useState, useContext } from "react"
import NumberFormat from "react-number-format"

import { LangContext } from "../../context/langContext"

import { Button } from "../buttons/buttons"
import { Container, Section } from "../grid/grid"
import { HeadingBasic } from "../heading/heading"

const CalculatorFinance = ({
  isAmp = false,
}) => {
  const { lang } = useContext(LangContext)
  const [formValues, setFormValues] = useState({
    price: "",
    downPayment: "",
    loanTerm: "",
    interestRate: "",
  })
  const [result, setResult] = useState(0)

  const regex = /^[0-9,\b]+$/
  const pattern = /(\d+),(?=\d{3}(\D|$))/g

  const priceChange = e => {
    const unformatValues = e.target.value.replace(pattern, "$1").split("Rp")
    setFormValues({ ...formValues, price: parseInt(unformatValues[1]) })
  }

  const downPaymentChange = e => {
    const unformatValues = e.target.value.replace(pattern, "$1").split("Rp")
    setFormValues({ ...formValues, downPayment: parseInt(unformatValues[1]) })
  }

  const loanTermChange = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFormValues({ ...formValues, loanTerm: parseInt(e.target.value) })
    }
  }

  const interestRateChange = e => {
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFormValues({ ...formValues, interestRate: parseInt(e.target.value) })
    }
  }

  const disableButton = () => {
    if (
      formValues.price !== "" &&
      formValues.downPayment !== "" &&
      formValues.loanTerm !== "" &&
      formValues.interestRate !== ""
    ) {
      return false
    } else {
      return true
    }
  }

  const calculateMortgage = e => {
    e.preventDefault()
    const totalLoan = formValues.price - formValues.downPayment
    const loanTermInMonths = formValues.loanTerm * 12
    const tenor = totalLoan / loanTermInMonths
    const totalInstallments = tenor * (formValues.interestRate / 100) + tenor

    let x = totalInstallments.toFixed(2).toString()
    const pattern = /(-?\d+)(\d{3})/
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2")

    setResult(x)
  }

  const resetResult = () => setResult(0)

  return (
    <Section className="project-financing">
      <Container className="mw-lg">
        {lang === "EN" ? (
          <HeadingBasic
            caption="Make it yours"
            h2="Financing Available"
            text={[
              "Sinarmas Land is the largest and most diversed property developer in Indonesia.",
            ]}
            className="mb-5"
          />
        ) : (
          <HeadingBasic
            caption="Make it yours"
            h2="Pembiayaan Tersedia"
            text={[
              "Sinarmas Land adalah pengembang properti terbesar dan paling beragam di Indonesia.",
            ]}
            className="mb-5"
          />
        )}
        {isAmp ? (
        <form className="row" action="#" target="_top">
          <div className="col-md-5 col-12">
            <div className="form-group">
              <label>
                {lang === "EN"
                  ? "Loan Amount/Property Price"
                  : "Jumlah Pinjaman / Harga Properti "}
              </label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                thousandSeparator={true}
                prefix={"Rp"}
                placeholder={
                  lang === "EN"
                    ? "Enter Down payment amount"
                    : "Masukkan jumlah uang muka"
                }
                onChange={priceChange}
              />
              {/* <input
                type="text"
                placeholder="Enter loan amount/property price"
                className="form-control form-control-lg px-0"
                value={formValues.price}
                onChange={priceChange}
              /> */}
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="form-group">
              <label>{lang === "EN" ? "Down Payment" : "Uang muka"}</label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                thousandSeparator={true}
                prefix={"Rp"}
                placeholder={
                  lang === "EN"
                    ? "Enter Down payment amount"
                    : "Masukkan jumlah uang muka"
                }
                onChange={downPaymentChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Down payment amount"
                value={formValues.downPayment}
                onChange={downPaymentChange}
              /> */}
            </div>
          </div>
          <div className="col-md-5 col-12">
            <div className="form-group">
              <label>
                {lang === "EN" ? "Loan term" : "Jangka waktu pinjaman"}
              </label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                placeholder={
                  lang === "EN"
                    ? "Enter Loan term"
                    : "Masukkan istilah Pinjaman"
                }
                onChange={loanTermChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Loan term"
                value={formValues.loanTerm}
                onChange={loanTermChange}
              /> */}
              <span className="form-span">
                {lang === "EN" ? "Years" : "Tahun"}
              </span>
            </div>
          </div>
          <div className="col-md col-12">
            <div className="form-group">
              <label>{lang === "EN" ? "Interest Rate" : "Suku bunga"}</label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                placeholder={
                  lang === "EN" ? "Enter Interest rate" : "Masukan Suku Bunga"
                }
                onChange={interestRateChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Interest rate"
                value={formValues.interestRate}
                onChange={interestRateChange}
              /> */}
              <span>%</span>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex">
            <Button
              variant="primary"
              className="btn-block align-self-center"
              onClick={calculateMortgage}
              disabled={disableButton()}
            >
              {lang === "EN" ? "Calculate" : "Hitung"}
            </Button>
          </div>
        </form>
        ):(
        <form className="row">
          <div className="col-md-5 col-12">
            <div className="form-group">
              <label>
                {lang === "EN"
                  ? "Loan Amount/Property Price"
                  : "Jumlah Pinjaman / Harga Properti "}
              </label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                thousandSeparator={true}
                prefix={"Rp"}
                placeholder={
                  lang === "EN"
                    ? "Enter Down payment amount"
                    : "Masukkan jumlah uang muka"
                }
                onChange={priceChange}
              />
              {/* <input
                type="text"
                placeholder="Enter loan amount/property price"
                className="form-control form-control-lg px-0"
                value={formValues.price}
                onChange={priceChange}
              /> */}
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="form-group">
              <label>{lang === "EN" ? "Down Payment" : "Uang muka"}</label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                thousandSeparator={true}
                prefix={"Rp"}
                placeholder={
                  lang === "EN"
                    ? "Enter Down payment amount"
                    : "Masukkan jumlah uang muka"
                }
                onChange={downPaymentChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Down payment amount"
                value={formValues.downPayment}
                onChange={downPaymentChange}
              /> */}
            </div>
          </div>
          <div className="col-md-5 col-12">
            <div className="form-group">
              <label>
                {lang === "EN" ? "Loan term" : "Jangka waktu pinjaman"}
              </label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                placeholder={
                  lang === "EN"
                    ? "Enter Loan term"
                    : "Masukkan istilah Pinjaman"
                }
                onChange={loanTermChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Loan term"
                value={formValues.loanTerm}
                onChange={loanTermChange}
              /> */}
              <span className="form-span">
                {lang === "EN" ? "Years" : "Tahun"}
              </span>
            </div>
          </div>
          <div className="col-md col-12">
            <div className="form-group">
              <label>{lang === "EN" ? "Interest Rate" : "Suku bunga"}</label>
              <NumberFormat
                className="form-control form-control-lg px-0"
                placeholder={
                  lang === "EN" ? "Enter Interest rate" : "Masukan Suku Bunga"
                }
                onChange={interestRateChange}
              />
              {/* <input
                className="form-control form-control-lg px-0"
                type="text"
                placeholder="Enter Interest rate"
                value={formValues.interestRate}
                onChange={interestRateChange}
              /> */}
              <span>%</span>
            </div>
          </div>
          <div className="col-md-3 col-12 d-flex">
            <Button
              variant="primary"
              className="btn-block align-self-center"
              onClick={calculateMortgage}
              disabled={disableButton()}
            >
              {lang === "EN" ? "Calculate" : "Hitung"}
            </Button>
          </div>
        </form>
        )}
        <div className={`form-result ${result !== 0 ? "show" : "hide"}`}>
          <p className="text-center label">
            {lang === "EN"
              ? "Estimated Monthly Installments"
              : "Estimasi Angsuran Bulanan"}
          </p>
          <h3 className="text-center installments">Rp{result}</h3>
          <button onClick={resetResult} className="btn-close" aria-label="btn-close">
            <i className="fas fa-times" />
          </button>
        </div>
        {lang === "EN" ? (
          <p className="text-muted mt-3">
            {" "}
            This calculation is an estimate and not a depiction of actual
            payment plan{" "}
          </p>
        ) : (
          <p className="text-muted mt-3">
            {" "}
            Perhitungan ini merupakan perkiraan dan bukan gambaran dari rencana
            pembayaran yang sebenarnya{" "}
          </p>
        )}
      </Container>
    </Section>
  )
}

export default CalculatorFinance
