import React, { useEffect, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import Modal from "react-modal"
import OtpInput from "react-otp-input"
import gql from "graphql-tag"
import NumberFormat from "react-number-format"
import { submitUtm, extractCookie } from "../utils/handle-utm"

import {
  GET_PROJECT_DETAIL,
  GET_NEARBY_PROJECT,
  FETCH_EBROCHURE,
} from "../graphql/development-graphql"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import { Button } from "../components/buttons/buttons"
import { SocmedLinks } from "../components/socmed-links/socmed-links"
import { CoverMain } from "../components/cover-main/cover-main"
import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs"
import { HeadingBasic } from "../components/heading/heading"
import { Section, Container } from "../components/grid/grid"
import { GoogleMap, GoogleMapsEmbed } from "../components/google-map/google-map"
import { Cover } from "../components/cover/cover"
import { NavBottom } from "../components/nav-bottom/nav-bottom"
import {
  Stats,
  WpIconlist,
  WpGroup,
  Carousel,
  WpGroupSmartMove,
  WpFacilitiesIcon,
} from "../components/utils/render-functions"
import CalculatorFinance from "../components/calculator-finance"
import { Glider, GliderVirtualLink } from "../components/glider/glider"
import { Button } from "../components/buttons/buttons"

import icBus from "../assets/img/icons/ic-bus-white.png"
import icTrain from "../assets/img/icons/ic-train.png"
import icToll from "../assets/img/icons/ic-toll-white.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const STATIC_IMAGE = graphql`
  query {
    imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const GET_OTP = gql`
  mutation getOtp($phoneNumber: String) {
    requestOtp(input: { clientMutationId: "", phoneNumber: $phoneNumber }) {
      status
      message
    }
  }
`

const SUBMIT_OTP = gql`
  mutation SubmitFormInfo(
    $fullName: String
    $email: String
    $phoneNumber: String
    $otpCode: String
    $propertyName: String
    $utm: String
    $projectsCode: String
    $clusterCode: String
  ) {
    submit2FormGetMoreInfo(
      input: {
        clientMutationId: ""
        fullName: $fullName
        email: $email
        phoneNumber: $phoneNumber
        otpCode: $otpCode
        propertyName: $propertyName
        utm: $utm
        projectsCode: $projectsCode 
        clusterCode: $clusterCode 
      }
    ) {
      status
      message
    }
  }
`

/* ==========================================================================
  Render Functions
   ========================================================================== */

const buttonScrollHandler = (target) => {
  var scrollContainer = target
  do { //find scroll container
    scrollContainer = scrollContainer.parentNode
    if (!scrollContainer) return
    scrollContainer.scrollTop += 1
  } while (scrollContainer.scrollTop == 0)

  var targetY = -60
  do { //find the top of target relatively to the container
    if (target == scrollContainer) break
    targetY += target.offsetTop
  } while (target = target.offsetParent)

  const scroll = function(c, a, b, i) {
    i++; if (i > 30) return
    c.scrollTop = a + (b - a) / 30 * i
    setTimeout(function(){ scroll(c, a, b, i); }, 20)
  }
  // start scrolling
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
}

const CustomFields = ({
  data,
  nearbyProjects,
  lat,
  long,
  nearbyData,
  eBrochureLink,
  project,
  lang,
  embedMaps,
  // eCatalogUrl,
}) => {
  // const { lang } = useContext(LangContext)
  if (data.attributes.text === "map-location") {
    console.log("cek_embed === ",embedMaps)
    return <GoogleMapsEmbed embedUrl={embedMaps} />
  } else if (
    data.attributes.text === "carousel-nearby" &&
    nearbyData &&
    nearbyData?.length > 0
  ) {
    let detail = []
    nearbyProjects &&
      nearbyProjects.map(item => {
        detail.push({
          image: item.node.featuredImage
            ? item.node.featuredImage.sourceUrl
            : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg",
          propertyName: item.node.title,
          location:
            item.node.locations.nodes.length > 0
              ? item.node.locations.nodes[0].name
              : null,
          description: item.node.excerpt || null,
          linkTo:
            item.node.propertyTypes.nodes[0].slug === "properti"
              ? `/wishforhome/${item.node?.locations?.nodes[0]?.slug}/${item.node.slug}`
              : item.node.propertyTypes.nodes[0].slug === "kavling"
                ? `/wishforhome/${item.node?.propertyTypes?.nodes[0]?.slug}/${item.node?.locations?.nodes[0]?.slug}/${item.node.slug}`
                : `/development/${item.node?.propertyTypes?.nodes[0]?.slug}/${item.node.slug}`,
        })
      })

    return (
      <div className="container px-sm-down-0 mb-md-3">
        <Glider id="nearby" data={detail} />
      </div>
    )
  } else if (data.attributes.text === "calculator-finance") {
    return <div id="fin-pro"><CalculatorFinance key={`calculator-finance`} /></div>
  } else if (data.attributes.text === "access-transportation") {
    return (
      <Section key={`access-transportation`}>
        <Container className="mw-md">
          <HeadingBasic
            className="mb-4"
            h2={
              lang === "EN" ? "Access & Transportation" : "Akses & Transportasi"
            }
            text={[
              lang === "EN"
                ? "Sinarmas Land is the largest and most diversed property developer in Indonesia. The company is known for its rich experience of more than 40 years in the field of property development"
                : "Sinarmas Land adalah pengembang properti terbesar dan paling beragam di Indonesia. Perusahaan ini dikenal dengan pengalamannya selama lebih dari 40 tahun di bidang pengembangan properti ",
            ]}
          />
          <Link
            to="/development/township/bsd-city/toll-road"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icToll" className="access-icon" src={icToll} />
              {lang === "EN" ? "Toll Road" : "Jalan Tol"}
            </span>
          </Link>
          <Link
            to="/development/township/bsd-city/train-station"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icTrain" className="access-icon" src={icTrain} />
              {lang === "EN" ? "Train Station" : "Stasiun Kereta"}
            </span>
          </Link>
          <Link
            to="/development/township/bsd-city/bus-station"
            className="btn btn-outline-dark mt-3"
          >
            <span>
              <img alt="icBus" className="access-icon" src={icBus} />
              {lang === "EN" ? "Bus Station" : "Terminal Bus"}
            </span>
          </Link>
        </Container>
      </Section>
    )
  } else if (data.attributes.text === "e-brochure") {
    return (
      <div className="e-brochure-access py-main">
        <Container>
          <div className="row">
            <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center">
              <p className="text">
                {lang === "EN" ? "Let's calculate the estimated cost of your dream property." : "Ayo hitung perkiraan biaya properti impian Anda."}
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                className="btn-download-outline"
                onClick={() => buttonScrollHandler(document.getElementById('fin-pro'))}
              >
                {lang === "EN" ? "Calculate here" : "Hitung disini"}
              </Button>
              {/* <Button
                variant="primary"
                className="btn-download-outline"
                onClick={() => {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "DownloadBrochure",
                    page_url: window.location.href,
                    page_title: project?.projectBy?.title,
                    content_type: "home_listing",
                    content_ids: project?.projectBy?.slug.replaceAll("-", "_"),
                    city: project?.projectBy?.locations?.nodes[0]?.slug,
                    region: project?.projectBy?.locations?.nodes[0]?.slug,
                    country: "Indonesia",
                  })
                  window.open(eBrochureLink, "_blank")
                }}
              >
                {lang === "EN" ? "e-Brochure" : "e-Brosur"}
              </Button> */}
              &nbsp;&nbsp;&nbsp;
              {/* <Button
                onClick={eCatalogUrl}
                className="btn btn-ecatalog-theme"
              >
                e-Catalog
              </Button> */}
            </div>
          </div>
        </Container>
      </div>
    )
  }

  return <div></div>
}

const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading, lang }) => {
  // const lang = (window && window.location.pathname?.split('/')[1] === "id") ? "ID" : "EN"
  const phoneNumberHandler = (val, setFieldValue) =>
    setFieldValue("phoneNumber", val)
  return (
    <Form className="row pt-4">
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">{lang === "EN" ? "Name" : "Nama"}</label>
        <Field
          name="name"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your name" : "Masukan nama anda"}
          type="text"
        />
        {touched.name && errors.name && (
          <div className="invalid-value d-block">{errors.name}</div>
        )}
      </div>
      <div className="col-12 col-md-4 pb-4 pb-md-0">
        <label className="text-muted my-0">Email</label>
        <Field
          name="email"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your email" : "Masukan email anda"}
          type="text"
        />
        {touched.email && errors.email && (
          <div className="invalid-value d-block">{errors.email}</div>
        )}
      </div>
      <div className="col-12 col-md-4">
        <label className="text-muted my-0">{lang === "EN" ? "Phone Number" : "Nomor Telepon"}</label>
        <NumberFormat
          name="phoneNumber"
          className="form-control px-0"
          placeholder={lang === "EN" ? "Enter your phone number" : "Masukan nomor telepon anda"}
          allowEmptyFormatting
          prefix="+62"
          value={values.phoneNumber}
          onValueChange={val =>
            phoneNumberHandler(val.formattedValue, setFieldValue)
          }
          type="tel"
        />
        {touched.phoneNumber && errors.phoneNumber && (
          <div className="invalid-value d-block">{errors.phoneNumber}</div>
        )}
      </div>
      <div className="col-12 pt-5">
        <button disabled={btnLoading} className="btn btn-primary w-100 w-md-25">
          {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </button>
        <p className="pt-3 text-muted">
          {lang === "EN" ?
            "Sinar Mas Land will never sell or share your information with a third party"
            : "Sinar Mas Land tidak akan pernah menjual atau membagikan informasi Anda kepada pihak ketiga"
          }
        </p>
      </div>
    </Form>
  )
}

const Blocks = ({
  item,
  i,
  detail,
  EnhancedLeadForm,
  getOtpLoading,
  nearby,
  projectDetail,
  eBrochureData,
  lang,
  mapsEmbedData,
  eCatalogUrlDetail,
  withButtonWa,
}) => {
  switch (item?.name) {
    case "acf/virtual-link":
      let links = []
      item.acf.cards.map(item => {
        links.push({
          image: item.image
            ? item.image.mediaItemUrl
            : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg",
          propertyName: item.title,
          description: item.description || null,
          linkTo: item.link && item.link.url,
          target: item.link && item.link.target,
        })
      })
      return (
        <Container
          className="py-main px-sm-down-0 mb-md-3"
          key={`virtual-link-${i}`}
        >
          <h2 className="pb-3 px-sm-down-3">{item.acf.title}</h2>
          <GliderVirtualLink data={links} />
        </Container>
      )
    case "core/heading":
      return (
        <Container key={`heading-${i}`}>
          <div className={i === 0 ? "py-main" : ""}>
            <HeadingBasic h2={item.attributes.content} />
          </div>
        </Container>
      )
    case "core/buttons":
      if (
        item.innerBlocks[0].attributes.url &&
        item.innerBlocks[0].attributes.url.split(".").length > 1
      ) {
        return (
          <Container key={`button=${i}`}>
            <div className={i === 0 ? "py-main" : "pb-main"}>
              {/* <HeadingBasic /> */}
              <a
                href={
                  item.innerBlocks[0].attributes.url.split("https://").length >
                    1
                    ? item.innerBlocks[0].attributes.url
                    : `https://${item.innerBlocks[0].attributes.url}`
                }
                target="_blank"
                className="btn btn-link font-size-lg fx-underline"
                rel="noopener noreferrer"
                key={`${item.innerBlocks[0].attributes.url}-${i}`}
              >
                {item.innerBlocks[0].attributes.text} &nbsp;
                <i className="far fa-external-link"></i>
              </a>
            </div>
          </Container>
        )
      } else {
        return (
          <Container key={`button-${i}`}>
            <Link
              key={`${item.innerBlocks[0].attributes.url}-${i}`}
              to={item.innerBlocks[0].attributes.url}
              className="btn btn-link font-size-lg fx-underline"
            >
              {item.innerBlocks[0].attributes.text}
            </Link>
          </Container>
        )
      }
    case "core/paragraph":
      return (
        <Section key={item.attributes.content} className="pb-3 pt-0">
          <Container className="mw-lg">
            <HeadingBasic text={[item.attributes.content]} />
          </Container>
        </Section>
      )
    case "core/group":
      return (
        <div
          key={`${item.name}-${i}`}
          className={i === 0 || i === 1 ? "py-main pt-3 pb-3" : "pb-main pt-3 pb-3"}
        >
          {/* <WpGroup
            data={item}
          // path={`/development/${projectDetail?.projectBy.propertyTypes.nodes[0].slug}`}
          /> */}

          <WpGroupSmartMove
            data={item}
            lang={lang}
          />
        </div>
      )
    case "acf/iconlist":
      return (
        <div
          key={`iconlist-${i}`}
          className={i === 0 ? "container py-main" : "container pb-main"}
        >
          <WpFacilitiesIcon data={item} />
        </div>
      )
    case "acf/labelvalues":
      let labelValuesPb
      if (
        detail.blocks[i + 1] !== undefined &&
        detail.blocks[i + 1]?.attributes !== undefined &&
        detail.blocks[i + 1]?.attributes.text === "map-location"
      ) {
        labelValuesPb = false
      } else {
        labelValuesPb = true
      }
      return (
        <div
          key={`${item.name}-${i}`}
          className={labelValuesPb ? "pb-main" : ""}
        >
          <Stats data={item} />
        </div>
      )
    case "acf/acfgallery":
      return (
        <div key={`carousel-${i}`}>
          <Section className="pt-0">
            <Carousel data={item} withLightbox={true} withButtonWa={withButtonWa}/>
          </Section>
        </div>
      )
    case "core/shortcode":
      let pbMain
      if (
        detail.blocks[i + 1] !== undefined &&
        detail.blocks[i + 1]?.attributes !== undefined &&
        (detail.blocks[i + 1]?.attributes.text === "calculator-finance" ||
          detail.blocks[i + 1]?.attributes.text === "e-brochure")
      ) {
        pbMain = false
      } else if (i === detail.blocks.length - 1) {
        pbMain = false
      } else {
        pbMain = true
      }
      return (
        <section
          key={`cutom_fields-${i}`}
          className={pbMain ? "pb-main" : "pb-md-0 pb-3"}
          id="gmi"
        >
          {item.attributes.text === "lead-form" ? (
            <Section className="sc-lead-form bg-light">
              <Container>
                <h2>{lang === "EN" ? "Get More Information" : "Dapatkan Lebih Banyak Informasi"}</h2>
                <p className="text-muted">{lang === "EN" ? "Find out more about this project" : "Ketahui tentang projek ini"}</p>
                <EnhancedLeadForm btnLoading={getOtpLoading} />
              </Container>
            </Section>
          ) : (
            <CustomFields
              data={item}
              nearbyProjects={nearby}
              lat={detail?.projectCustomfields?.latitude}
              long={detail?.projectCustomfields?.longitude}
              pre={projectDetail?.projectBy.propertyTypes.nodes[0].slug}
              nearbyData={projectDetail?.projectBy.nearby}
              pageTitle={projectDetail?.projectBy.title}
              project={projectDetail}
              eBrochureLink={
                eBrochureData?.eBrochure?.eBrochure?.file?.mediaItemUrl
              }
              lang={lang}
              embedMaps={mapsEmbedData}
              // eCatalogUrl={eCatalogUrlDetail}
            />
          )}
        </section>
      )
    case "core/embed":
      return (
        <div className="py-main">
          <Cover
            variant="basic"
            theme="dark"
            img={null}
            imgHeight="h-400px h-md-500px w-100"
            // imgOverlay="40"
            className="cover cover-full-top"
            // type="video-popup"
            videoSrc={item.attributes && item.attributes.url}
            type={{ name: "core-embed/youtube" }}
            audio={false}
          />
        </div>
      )
    default:
      return <div key={`empty-element-${i}`}></div>
  }
}

/* ==========================================================================
   ========================================================================== */

/* ==========================================================================
  Render
   ========================================================================== */

const ProjectDetail = ({ pageContext, location }) => {
  const {
    slug,
    title,
    projectCode,
    clusterCode,
    translation,
    lang,
    seo,
    leadSourceName,
    priceProduct,
    eCatalogProject,
    propertyTypes,
    mapsEmbedUrl,
    featuredImage,
    projectCustomfields,
  } = pageContext

  // const { lang } = useContext(LangContext)
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  })
  const [whatsappModal, setWhatsappModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [otp, setOtp] = useState()
  const [successModal, setSuccessModal] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [timeLimit, setTimeLimit] = useState(180)
  // const [attentionModal, setAttentionModal] = useState(false)
  // const [hasVisitor, setHasVisitor] = useState(false)

  // server-side --- static site generation
  const staticData = useStaticQuery(STATIC_IMAGE)

  // client-side --- dynamic fetching
  const { loading: intialLoading, data: projectDetail } = useQuery(
    GET_PROJECT_DETAIL,
    {
      variables: { slug },
    }
  )

  const { loading: eBrochureLoading, data: eBrochureData } = useQuery(
    FETCH_EBROCHURE,
    {
      variables: { id: slug },
    }
  )

  const [getNearby, { data }] = useLazyQuery(GET_NEARBY_PROJECT)
  const [getOtp, { loading: getOtpLoading }] = useMutation(GET_OTP)
  const [submitOtp, { loading: submitOtpLoading }] = useMutation(SUBMIT_OTP)

  // data variables
  const detail = lang === "EN" ? projectDetail?.projectBy : translation || projectDetail?.projectBy
  const cover =
    lang === "EN"
      ? pageContext &&
      pageContext?.blocks &&
      pageContext?.blocks.find(item => item.name === "core/cover")
      : translation?.blocks.find(item => item.name === "core/cover") ||
      pageContext?.blocks.find(item => item.name === "core/cover")
  const nearby = data ? data.projects.edges : null
  const projectByTypes = data
    ? data.propertyTypes.nodes[0].projects.nodes
    : null
  const currentProjectIndex = projectByTypes
    ? projectByTypes.findIndex(x => x.title === pageContext.title)
    : null

  const pageSeo = seo ? seo : pageContext.translation.seo

  const thisCurrrentUrl = process.env.BASE_URL + location.pathname

  let programmaticProjectList = ["terravia", "nordville"]

  const gtagFunction = (...data) => {
    return window.dataLayer.push(...data)
  }

  const gtag_tracking_conversion = (url) =>{
    const callback = () => {
      if (typeof (url) != "undefined") {
        window.location = url
      }
    }

    gtagFunction("event", "conversion", { 
      "send_to" : `AW-${process.env.CONVERSION_ID}/${process.env.CONVERSION_LABEL}`,
      'event_callback': callback
     })
    return false
  }

  const currencyFormat = (money) => {
    return new Intl.NumberFormat('id-ID',
      { minimumFractionDigits: 0 }
    ).format(money);
  }

  const handleCopy = () => {
    const thisInput = document.createElement("INPUT")

    document.body.append(thisInput)
    thisInput.value = location.href
    thisInput.select()
    document.execCommand("copy")
    thisInput.remove()
    setTimeout(function(){document.getElementById('copyButton').innerHTML = `<i class="fa fa-link"></i> <span class="tooltiptext">${lang === "EN" ? "URL has copied" : "Tautan telah disalin"}</span>`;}, 200)
    setTimeout(function(){document.getElementById('copyButton').innerHTML = '<i class="fa fa-link"></i>';}, 2000)
  }

  const PriceTag = () => {
    if(projectDetail?.projectBy?.propertyTypes?.nodes[0]?.slug !== "township"){
      return(
        <Section className="price-wrapper">
          {priceProduct !== null && priceProduct !== 0 && priceProduct > 0 ?(
            <Container className="container-md">
              <div className="row">
                <div className="col-md-6 detail-desc">
                  Enjoy Sinar Mas Land Special Offer
                </div>
                <div className="col-md-6 value-wrapper">
                  <div className="col-md-12 desc-price">Starts From</div>
                  <div className="col-md-12 price-money">
                    <Button
                      className="btn btn-link fx-underline"
                      onClick={() => buttonScrollHandler(document.getElementById('gmi'))}
                    >
                      <sup>Rp</sup><span className="total-price"> {currencyFormat(priceProduct)}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          ):(
            <Container className="container-md">
              <div className="row">
              <div className="col-md-6 detail-desc">
                  Enjoy Sinar Mas Land Special Offer
                </div>
                <div className="col-md-6 coming-soon">
                  <Button
                    className="btn btn-link fx-underline"
                    onClick={() => buttonScrollHandler(document.getElementById('gmi'))}
                  >
                    { lang === "EN"
                      ? "Reach Us Now!"
                      : "Hubungi Kami Sekarang!"
                    }
                  </Button>
                </div>
              </div>
            </Container>
          )}
        </Section>
      )
    }else{
      return(
        <div></div>
      )
    }
  }

  useEffect(() => {
    if (projectDetail && !data) {
      getNearby({
        variables: {
          id: projectDetail?.projectBy.nearby,
          slug: projectDetail?.projectBy.propertyTypes.nodes[0].slug,
          language: lang,
        },
      })
      productDetailDataLayer()
    }
  }, [projectDetail])

  useEffect(() => {
    let timer = setTimeout(() => {
      if (timeLimit >= 0 && showModal) {
        setTimeLimit(timeLimit - 1)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [timeLimit, showModal])

  useEffect(() => {
    const modalElement = document.querySelectorAll('.ReactModalPortal');

    if (modalElement.length > 0) {
      for (var i = 0; i < modalElement.length; i++) {
        modalElement[i].removeAttribute('inert')
        modalElement[i].removeAttribute('aria-hidden')
      }
    }

    return () => {
      if (modalElement) {
        modalElement.removeAttribute('inert');
        modalElement.removeAttribute('aria-hidden');
      }
    };
  }, [])

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)
      const formatNumber = values.phoneNumber.split("+").join("")
      getOtp({ variables: { phoneNumber: formatNumber } }).then(res => {
        if (res.data.requestOtp.status === "success") {
          setShowModal(true)
        }
      })
    },
  })((props) => LeadForm({ ...props, lang }))

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "888889",
    },
  }

  const handleCloseWhatsappModal = () => {{
    setWhatsappModal(false)
  }}

  const handleWhatsappModal = () => {{
    setWhatsappModal(true)

    const modalElement = document.querySelectorAll('.ReactModalPortal');

    if (modalElement.length > 0) {
      for (var i = 0; i < modalElement.length; i++) {
        modalElement[i].removeAttribute('inert')
        modalElement[i].removeAttribute('aria-hidden')
      }
    }
  }}

  const handleSuccessModal = () => {{
    if(eCatalogProject?.url){
      window.open(eCatalogProject?.url, "_blank")
    }else{
      window.location.reload()
    }
    setSuccessModal(false)
  }}

  const handleOtp = otp => {
    setOtp(otp)
  }

  const getOtpHandler = () =>
    getOtp({
      variables: { phoneNumber: formValues.phoneNumber.split("+").join("") },
    }).then(res => {
      if (res.data.requestOtp.status === "success") {
        setTimeLimit(180)
        setShowModal(true)
      }
    })

  const sendOtp = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        otpCode: otp,
        propertyName: title + " - SMLWebsite",
        utm: extractCookie("utm_params=")
          ? JSON.stringify(extractCookie("utm_params="))
          : null,
        projectsCode: projectCode ? projectCode : "",
        clusterCode: clusterCode ? clusterCode : "",
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        if (programmaticProjectList.includes(slug)) {
          gtag_tracking_conversion(thisCurrrentUrl)
        }
        setDetailWFHDataLayer(setDataLayerValue("InitiateCheckout"))
        setShowModal(false)
        setSuccessModal(true)
        setTimeLimit(180)
        setFormValues({ fullName: "", email: "", phoneNumber: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        // submitUtm({
        //   fullname: formValues.name,
        //   email: formValues.email,
        //   mobile: formValues.phoneNumber,
        //   project_name: title,
        //   projects_code: projectCode,
        //   cluster_code: clusterCode,
        //   lead_source_name: leadSourceName,
        // })
      } else {
        setOtpError(true)
      }
    })
  }

  // const sendOtpJvProduct = () => {
  //   console.log("starting submit jv")
  //   submitUtm({
  //     fullname: formValues.name,
  //     email: formValues.email,
  //     mobile: formValues.phoneNumber,
  //     project_name: title,
  //     projects_code: projectCode,
  //     cluster_code: clusterCode,
  //     lead_source_name: leadSourceName,
  //   }).then(res => {
  //     console.log("waiting submit jv")
  //     if (res.status === 200) {
  //       console.log("submit utm successfull")
  //       setDetailWFHDataLayer(setDataLayerValue("InitiateCheckout"))
  //       setShowModal(false)
  //       setSuccessModal(true)
  //       setTimeLimit(180)
  //       setFormValues({ fullName: "", email: "", phoneNumber: "" })
  //       setOtp("")
  //       if (otpError) {
  //         setOtpError(false)
  //       }
  //     } else {
  //       setOtpError(true)
  //     }
  //   })
  // }

  const sendOtpJvProduct = () => {
    const formatNumber = formValues.phoneNumber.split("+").join("")

    submitOtp({
      variables: {
        fullName: formValues.name,
        email: formValues.email,
        phoneNumber: formatNumber,
        otpCode: otp,
        propertyName: title + " - SMLWebsite",
        utm: extractCookie("utm_params=")
          ? JSON.stringify(extractCookie("utm_params="))
          : null,
        projectsCode: projectCode ? projectCode : "",
        clusterCode: clusterCode ? clusterCode : "",
      },
    }).then(res => {
      if (res.data.submit2FormGetMoreInfo.status === "success") {
        if (programmaticProjectList.includes(slug)) {
          gtag_tracking_conversion(thisCurrrentUrl)
        }
        setDetailWFHDataLayer(setDataLayerValue("InitiateCheckout"))
        setShowModal(false)
        setSuccessModal(true)
        setTimeLimit(180)
        setFormValues({ fullName: "", email: "", phoneNumber: "" })
        setOtp("")
        if (otpError) {
          setOtpError(false)
        }
        submitUtm({
          fullname: formValues.name,
          email: formValues.email,
          mobile: formValues.phoneNumber,
          project_name: title,
          projects_code: projectCode,
          cluster_code: clusterCode,
          lead_source_name: leadSourceName,
        })
      } else {
        setOtpError(true)
      }
    })
  }

  const formatTimeLeft = time => {
    let minutes = Math.floor(time / 60)
    let seconds = time % 60
    if (seconds < 10 && seconds >= 0) {
      seconds = `0${seconds}`
    } else if (seconds < 0) {
      seconds = "00"
    }

    if (minutes < 10 && minutes >= 0) {
      minutes = `0${minutes}`
    } else if (minutes < 0) {
      minutes = "00"
    }
    return `${minutes}:${seconds}`
  }

  const brochureDownloadDataLayer = city => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "DownloadBrochure",
      page_url: window.location.href,
      page_title: title,
      content_type: "home_listing",
      content_ids: slug.replaceAll("-", "_"),
      city: city,
      region: city,
      country: "Indonesia",
    })
  }

  const changeNullToBeZero = val => {
    if (val == null) return 0
    return val
  }

  const setDataLayerValue = event => {
    const value = {
      event: event,
      page_url: window.location.href,
      page_title: title,
      content_type: "home_listing",
      content_ids: slug.replaceAll("-", "_"),
      city: projectDetail?.projectBy?.locations?.nodes[0]?.slug,
      region: projectDetail?.projectBy?.locations?.nodes[0]?.slug,
      country: "Indonesia",
      lease_start_date: "2021-01-01",
      lease_end_date: "2100-01-01",
      currency: "IDR",
      preferred_price_range: [
        projectDetail?.projectBy?.projectCustomfields?.preferredPriceRangeFrom,
        projectDetail?.projectBy?.projectCustomfields?.preferredPriceRangeTo,
      ],
      preferred_baths_range: [
        changeNullToBeZero(
          projectDetail?.projectBy?.projectCustomfields?.preferredBathsRangeFrom
        ),
        changeNullToBeZero(projectDetail?.projectBy?.projectCustomfields?.preferredBathsRangeTo),
      ],
      preferred_bed_range: [
        changeNullToBeZero(projectDetail?.projectBy?.projectCustomfields?.preferredBedRangeFrom),
        changeNullToBeZero(projectDetail?.projectBy?.projectCustomfields?.preferredBedRangeTo),
      ],
      property_type: propertyTypes,
    }
    return value
  }

  const setDetailWFHDataLayer = value => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(value)
  }

  const productDetailDataLayer = () => {
    setDetailWFHDataLayer(setDataLayerValue("ViewContent"))
  }

  // const pageSeo = seo ? seo : pageContext.translation.seo

  return (
    <Layout location={location}
      // hideFooter={detail && nearby && !intialLoading ? false : true}
      lang={lang}
      isMicrodataProduct={true}
      isWaForm={true}
      waFormClick={()=>setWhatsappModal(true)}
    >
      <SEO
        title={pageSeo?.title}
        fbTitle={pageSeo?.opengraphTitle || pageSeo?.title}
        twitterTitle={pageSeo?.twitterTitle || pageSeo?.title}
        twitterDescription={pageSeo?.twitterDescription || seo?.opengraphDescription}
        fbDescription={pageSeo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={thisCurrrentUrl}
        fbImg={
          featuredImage
        }
        twitterImg={
          featuredImage
        }
        img={
          featuredImage
        }
        // schemaMarkup={schemaDetailProjects}
        ampUrl={true}
      />
      <>
        <Helmet>
          <body className="bd-page" />
          <meta name="keywords" content={pageSeo?.focuskw ? pageSeo?.focuskw : ""}></meta>
          <link rel="amphtml" href={thisCurrrentUrl.replace('/development/', '/development/amp/')}/>
          <link rel="canonical" href={thisCurrrentUrl}/>
        </Helmet>

        {cover && (
          <CoverMain
            label={lang === "EN" ? "Welcome To" : "Selamat Datang Di"}
            h1={
              lang === "EN"
                ? detail?.title
                : translation?.title || detail?.title
            }
            img={
              cover !== undefined
                ? cover.attributes.url
                : staticData.imgCoverMain.childImageSharp.fluid
            }
            alt={detail?.title}
            className="cover-md cover-detail bg-cover"
          >
            <Breadcrumbs
              path={location.pathname}
              className="breadcrumb-light"
            />
          </CoverMain>
        )}
        <PriceTag />
        <div>
          <meta itemProp="mpn" content="925872" />
          <meta itemProp="name" content={pageSeo?.title} />
          <link itemProp="image" href={cover?.attributes?.url} />
          <meta itemProp="description" content={seo?.opengraphDescription} />
          <div itemProp="offers" itemType="https://schema.org/AggregateOffer" itemScope>
            <meta itemProp="lowPrice" content={priceProduct ? priceProduct : 0 } />
            <meta itemProp="highPrice" content={priceProduct ? priceProduct : 0 } />
            <meta itemProp="offerCount" content="10" />
            <meta itemProp="priceCurrency" content="IDR" />
          </div>
          <div itemProp="review" itemType="https://schema.org/Review" itemScope>
            <div itemProp="author" itemType="https://schema.org/Person" itemScope>
              <meta itemProp="name" content="Sinar Mas Land" />
            </div>
            <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
              <meta itemProp="ratingValue" content="4.5" />
              <meta itemProp="bestRating" content="5" />
            </div>
          </div>
          <meta itemProp="sku" content="0446310786" />
          <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
            <meta itemProp="name" content="Sinar Mas Land" />
          </div>
        </div>
        <Container className="share-projects mw-lg">
          <SocmedLinks
            facebookLink={thisCurrrentUrl}
            twitterLink={thisCurrrentUrl}
            linkedinLink={thisCurrrentUrl}
            copyLink={thisCurrrentUrl}
            onClick={handleCopy}
            isAmp={false}
            location={thisCurrrentUrl}
          />
        </Container>
        {detail?.blocks &&
          detail?.blocks.map((item, i) => {
            return (
              <Blocks
                item={item}
                i={i}
                detail={detail}
                EnhancedLeadForm={EnhancedLeadForm}
                getOtpLoading={getOtpLoading}
                nearby={nearby}
                projectDetail={projectDetail}
                eBrochureData={eBrochureData}
                lang={lang}
                mapsEmbedData={mapsEmbedUrl}
                // eCatalogUrlDetail={() => {setAttentionModal(true)}}
                withButtonWa={handleWhatsappModal}
              />
            )
          })}

        {projectByTypes && projectByTypes.length > 2 && (
          <NavBottom
            prevImg={
              projectByTypes[currentProjectIndex - 1]
                ? projectByTypes[currentProjectIndex - 1].featuredImage
                  ? projectByTypes[currentProjectIndex - 1].featuredImage
                    .sourceUrl
                  : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
                : projectByTypes[projectByTypes.length - 1].featuredImage
                  ? projectByTypes[projectByTypes.length - 1].featuredImage
                    .sourceUrl
                  : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
            }
            prevTitle={
              projectByTypes[currentProjectIndex - 1]
                ? projectByTypes[currentProjectIndex - 1].title
                : projectByTypes[projectByTypes.length - 1].title
            }
            prevLink={
              projectByTypes[currentProjectIndex - 1]
                ? `/development/${projectDetail?.projectBy?.propertyTypes?.nodes[0]?.slug
                }/${projectByTypes[currentProjectIndex - 1].slug}`
                : `/development/${projectDetail?.projectBy.propertyTypes.nodes[0].slug
                }/${projectByTypes[projectByTypes.length - 1].slug}`
            }
            nextImg={
              projectByTypes[currentProjectIndex + 1]
                ? projectByTypes[currentProjectIndex + 1].featuredImage
                  ? projectByTypes[currentProjectIndex + 1].featuredImage
                    .sourceUrl
                  : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
                : projectByTypes[0].featuredImage
                  ? projectByTypes[0].featuredImage.sourceUrl
                  : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/03/imgBsdCity.jpg"
            }
            nextTitle={
              projectByTypes[currentProjectIndex + 1]
                ? projectByTypes[currentProjectIndex + 1].title
                : projectByTypes[0].title
            }
            nextLink={
              projectByTypes[currentProjectIndex + 1]
                ? `/development/${projectDetail?.projectBy.propertyTypes.nodes[0].slug
                }/${projectByTypes[currentProjectIndex + 1].slug}`
                : `/development/${projectDetail?.projectBy.propertyTypes.nodes[0].slug}/${projectByTypes[0].slug}`
            }
          />
        )}
        <Modal
          isOpen={whatsappModal}
          onRequestClose={handleCloseWhatsappModal}
          style={customStyles}
          contentLabel="campaign modal"
        >
          <div className="modal-whatsapp-form">
            <i className="fal fa-times" onClick={handleCloseWhatsappModal}></i>
            <div className="container py-5 mw-100 mw-md-75">
              <EnhancedLeadForm />
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={showModal}
          style={customStyles}
          contentLabel="campaign modal"
        >
          <div className="modal-popup-otp modal-body text-center">
            <div className="container py-5 mw-md-75 mw-100">
              <h2>OTP</h2>
              <p className="m-0">
                To proceed, please enter the 6-digits OTP code sent to your
                mobile number
              </p>
              <p className="font-weight-bold">{formValues.phoneNumber}</p>
              <OtpInput
                value={otp}
                containerStyle="otp-container"
                inputStyle={`otp-input ${otpError ? "error" : ""}`}
                onChange={handleOtp}
                numInputs={6}
                isInputNum
              />
              {otpError && (
                <p className="otp-error mt-3">
                  The code you entered does not match, please re-enter.
                </p>
              )}
              {getOtpLoading ? (
                <p className="mt-md-5 mt-3 text-primary">
                  <i className="fa fa-spinner fa-spin"></i>
                </p>
              ) : timeLimit > 0 ? (
                <p className="mt-md-5 mt-3 text-muted">
                  Haven’t received OTP code? Resend ({timeLimit})
                </p>
              ) : (
                <button
                  className="btn mt-md-5 mt-3 text-primary"
                  onClick={getOtpHandler}
                >
                  Haven’t received OTP code? <strong>Resend</strong>
                </button>
              )}
              <button
                disabled={submitOtpLoading}
                className={`btn btn-primary w-50 mt-3 ${projectCode === "JV" ? "JV" : ""}`}
                onClick={projectCode === "JV" ? sendOtpJvProduct : sendOtp}
              >
                {submitOtpLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={successModal}
          onRequestClose={handleSuccessModal}
          style={customStyles}
          contentLabel="campaign modal"
        >
          <div className="modal-popup-otp modal-body text-center">
            <div className="container py-5 mw-100 mw-md-75">
              {/* <img alt="success" src={icSuccess} className="mw-25 mb-4" /> */}
              <h2>OTP Verified!</h2>
              <p className="m-0">
                Your data has been submitted. Our Sinar Mas Land team will reach
                out to you.
              </p>
                <button
                  className="btn btn-ecatalog-theme mt-4"
                  onClick={handleSuccessModal}
                >
                  Visit e-Catalog
                </button>
              {/* {hasVisitor ? (
                <button
                  className="btn btn-ecatalog-theme mt-4"
                  onClick={handleSuccessModal}
                >
                  Visit e-Catalog
                </button>
              ) : (
                <button
                  className="btn btn-primary w-50 mt-4"
                  onClick={handleSuccessModal}
                >
                  Ok
                </button>
              )} */}
            </div>
          </div>
        </Modal>
      </>
    </Layout>
  )
}

export default ProjectDetail
